import React, { useEffect, useRef, useState } from "react";
import { View, Platform, ScrollView } from "react-native";
import { useTranslation } from "react-i18next";
import { Text, theme, useResponsive } from "@clearabee/ui-library-native";
import { LoginForm } from "components/LoginForm/LoginForm";
import { LoginLayout } from "components/LoginLayout/LoginLayout";
import { styles } from "./LoginOrRegister.styles";
import { Switch } from "components/Switch/Switch";
import { RegisterForm } from "components/RegisterForm/RegisterForm";
import { Trophy } from "components/LoginLayout/Trophy";
import { ButtonLink } from "components/Core/ButtonLink/ButtonLink";

interface LoginOrRegisterProps {
  defaultTab: string;
}

export const LoginOrRegister = ({
  defaultTab,
}: LoginOrRegisterProps): React.ReactElement => {
  const [translate] = useTranslation();
  const { screens } = useResponsive();
  const [selectedTab, setTab] = useState(defaultTab);
  const [isSuccess, setSuccess] = useState(false);
  const scrollRef = useRef<ScrollView>(null);

  useEffect(() => {
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [selectedTab]);

  return (
    <>
      <LoginLayout
        title={translate(
          isSuccess
            ? "hwa.components.loginOrRegister.successTitle"
            : "hwa.components.loginOrRegister.title",
        )}
        subtitle={translate(
          isSuccess
            ? "hwa.components.loginOrRegister.successSubtitle"
            : "hwa.components.loginOrRegister.subtitle",
        )}
        scrollRef={scrollRef}
        hideWebHeading={!isSuccess}
        backTo={
          Platform.OS !== "web"
            ? {
                routeName: "Dashboard",
              }
            : undefined
        }
      >
        {isSuccess && (
          <View style={{ alignItems: "center" }}>
            <View
              style={{
                marginTop: theme.spacing.small,
                marginBottom: theme.spacing.medium,
              }}
            >
              <Trophy />
            </View>

            <Text color="light" fontSize="small" styles={styles.successEmail}>
              {translate("hwa.components.loginOrRegister.successEmail")}
            </Text>

            <ButtonLink
              routeName="Login"
              color="warning"
              size={screens.medium ? "medium" : "large"}
              onPress={() => {
                if (Platform.OS === "web") {
                  return;
                }

                setSuccess(false);
                setTab("Login");
              }}
            >
              {translate("common.login")}
            </ButtonLink>
          </View>
        )}
        {!isSuccess && (
          <>
            <View style={{ marginBottom: theme.spacing.medium }}>
              <Switch
                tabs={[
                  {
                    name: "Login",
                    routeName: "Login",
                    title: translate("common.login"),
                  },
                  {
                    name: "Register",
                    routeName: "Register",
                    title: translate("common.createAccount"),
                  },
                ]}
                selectedTab={selectedTab}
                onSwitch={setTab}
              />
            </View>
            {Platform.OS !== "web" && selectedTab === "Login" && (
              <Text color="light" styles={styles.websiteCredentials}>
                {translate(
                  "hwa.components.loginOrRegister.useClearabeeCredentials",
                )}
              </Text>
            )}

            {selectedTab === "Login" ? (
              <LoginForm />
            ) : (
              <RegisterForm onSuccessfulSignUp={() => setSuccess(true)} />
            )}
          </>
        )}
      </LoginLayout>
    </>
  );
};

import React from "react";
import { View } from "react-native";
import { Text } from "@clearabee/ui-library-native";
import { styles } from "./Switch.styles";
import { SwitchProps } from "./types";
import Link from "next/link";
import { routes } from "lib/routes";

interface WrapperProps {
  children: React.ReactNode;
}

export const Switch = ({
  selectedTab,
  tabs,
}: SwitchProps): React.ReactElement => {
  const position = tabs?.findIndex(({ name }) => name === selectedTab) ?? 0;

  return (
    <View style={styles.wrapper}>
      {tabs.map(({ title, name, routeName, params }, index) => {
        const isSelected = position === index;
        const href = {
          pathname: routes[routeName].path,
          query: Object.entries(params ?? {}).reduce(
            (params, [key, value]) =>
              value ? { ...params, [key]: value } : params,
            {},
          ),
        };
        const Wrapper = (props: WrapperProps) =>
          isSelected ? (
            <React.Fragment {...props} />
          ) : (
            <Link {...props} href={href} passHref legacyBehavior />
          );

        return (
          <Wrapper key={`${name}-${index}`}>
            <View
              style={[
                {
                  width: isSelected ? "53%" : "47%",
                  height: "100%",
                  justifyContent: "center",
                },
                isSelected && styles.active,
              ]}
            >
              <Text
                fontSize="small"
                color={isSelected ? "dark" : "light"}
                styles={styles.text}
              >
                {title}
              </Text>
            </View>
          </Wrapper>
        );
      })}
    </View>
  );
};

import { theme } from "@clearabee/ui-library-native";
import { Platform } from "react-native";
import ResponsiveStyleSheet from "react-native-media-query";

export const { styles, ids } = ResponsiveStyleSheet.create({
  fieldsContainer: Platform.select({
    native: {
      marginHorizontal: theme.spacing.medium,
    },
    default: {},
  }),
  fieldLabel: { color: theme.colors.light.base },
  fieldError: Platform.select({
    web: {
      position: "absolute",
      bottom: -29,
    },
    default: {
      marginBottom: -theme.spacing.xsmall,
    },
  }),
  spinnerContainer: {
    position: "absolute",
    marginLeft: -theme.spacing.large,
  },
  spinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  linksContainer: {
    marginTop: theme.spacing.small,
    alignItems: "center",
  },
  authError: {
    marginTop: theme.spacing.medium,
    backgroundColor: theme.colors.negative.base,
  },
});

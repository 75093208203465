import { z } from "zod";

export const validationSchema = z.object({
  email: z.string().min(1).email(),
  password: z.string().min(1),
});

export const initialValues = {
  email: "",
  password: "",
};

import { z } from "zod";
import { translate } from "@clearabee/i18n";

export const validationSchema = z
  .object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    email: z.string().min(1).email(),
    password: z.string().min(1).min(6),
    confirmPassword: z.string().min(1),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: translate("common.form.errors.matchField"),
    path: ["confirmPassword"],
  })
  .transform(({ confirmPassword, ...values }) => values);

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

import React, { useState } from "react";
import { Platform, View } from "react-native";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  FlexGrid,
  Message,
  theme,
  LinkText,
  useResponsive,
  Text,
  Link,
} from "@clearabee/ui-library-native";
import { AuthError } from "lib/providers/authProvider";
import { useAuth } from "lib/hooks/useAuth";
import { Input } from "components/Core/Input/Input";
import { Button } from "components/Core/Button/Button";
import { useForm, Form } from "lib/hooks/useForm";
import { Anchor } from "components/Core/Anchor/Anchor";
import { Spinner } from "components/Core/Spinner/Spinner";
import { styles, ids } from "./LoginForm.styles";
import { initialValues, validationSchema } from "./LoginForm.validation";

export const LoginForm = (): React.ReactElement => {
  const { signIn, resendSignUpLink } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [translate] = useTranslation();
  const [authError, setAuthError] = useState<string>();
  const { screens } = useResponsive();

  const { mutate: mutateResendSignUpLink, isError: isErrorResendSignUpLink } =
    useMutation(
      "resendSignUpLink",
      async (email: string) => {
        setLoading(true);

        return await resendSignUpLink(email);
      },
      {
        onSuccess: () => {
          setAuthError(translate("hwa.screens.login.errors.resendSignUpLink"));
        },
        onSettled: () => setLoading(false),
      },
    );

  const form = useForm({
    defaultValues: initialValues,
    validationSchema,
    onSubmit: async ({ email, password }, { setError }) => {
      setLoading(true);
      setAuthError(undefined);

      try {
        await signIn(email, password);
      } catch (error: any) {
        if (
          error?.code === "UserNotConfirmedException" &&
          error?.message.includes("User is not confirmed")
        ) {
          return mutateResendSignUpLink(email);
        }

        if (error instanceof AuthError) {
          setAuthError(error.message);
        } else {
          setError("email", {
            message:
              (error as Error).name === "NotAuthorizedException"
                ? translate("common.form.errors.incorrectUsernameOrPassword")
                : (error as Error).message,
          });
        }
      }

      setLoading(false);
    },
  });
  const { submitForm } = form;

  return (
    <Form state={form}>
      {({ Field }) => (
        <>
          <FlexGrid rowSpacing={null} style={styles.fieldsContainer}>
            <FlexGrid.Cell base="100%">
              <Field
                name="email"
                label={translate("common.emailAddress")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate(
                      "common.form.placeholder.emailAddress",
                    )}
                  />
                )}
              </Field>
            </FlexGrid.Cell>
            <FlexGrid.Cell base="100%">
              <Field
                name="password"
                label={translate("common.password")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate("common.form.placeholder.password")}
                    secureTextEntry
                  />
                )}
              </Field>
            </FlexGrid.Cell>
          </FlexGrid>

          <View
            style={styles.linksContainer}
            dataSet={{ media: ids.linksContainer }}
          >
            <Anchor routeName="PasswordReset">
              <LinkText underline color="light">
                {translate("common.forgotPassword")}?
              </LinkText>
            </Anchor>
            <View
              style={{ flexDirection: "row", marginTop: theme.spacing.medium }}
            >
              {isLoading && (
                <View style={{ justifyContent: "center" }}>
                  <View style={styles.spinnerContainer}>
                    <Spinner style={styles.spinner} />
                  </View>
                </View>
              )}
              <Button
                type="submit"
                color="warning"
                size={screens.medium ? "medium" : "large"}
                onClick={submitForm}
                disabled={isLoading}
              >
                {translate("hwa.components.loginForm.submit")}
              </Button>
            </View>
            {Platform.OS === "web" && (
              <Text
                color="light.base"
                styles={{ textAlign: "center", marginTop: theme.spacing.large }}
              >
                {translate("hwa.screens.login.commercialLoginPrompt")}
                <Anchor
                  routeName="Redirect"
                  params={{ url: "https://portal.clearabee.co.uk" }}
                >
                  <LinkText underline color="light">
                    {translate("hwa.screens.login.commercialLoginPromptLink")}
                  </LinkText>
                </Anchor>
              </Text>
            )}
          </View>

          {!!authError && (
            <Message
              styles={styles.authError}
              color="light"
              type="error"
              background
            >
              {authError}
            </Message>
          )}

          {!!isErrorResendSignUpLink && (
            <Message
              styles={styles.authError}
              color="light"
              type="error"
              background
            >
              This email address is not verfied. Please{" "}
              <Link
                onPress={() => mutateResendSignUpLink(form.getValues("email"))}
              >
                <LinkText underline color="light">
                  {translate("hwa.screens.login.buttons.clickHere")}
                </LinkText>
              </Link>{" "}
              to receive a new verification link.
            </Message>
          )}
        </>
      )}
    </Form>
  );
};

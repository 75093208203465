import { theme } from "@clearabee/ui-library-native";
import ResponsiveStyleSheet from "react-native-media-query";

export const { styles, ids } = ResponsiveStyleSheet.create({
  wrapper: {
    backgroundColor: theme.colors.brand.darkest,
    borderRadius: theme.spacing.xlarge,
    height: theme.spacing.xlarge3,
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  active: {
    height: theme.spacing.xlarge3 + theme.spacing.xsmall,
    backgroundColor: theme.colors.warning.base,
    borderRadius: theme.spacing.xlarge,
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    fontFamily: "ProximaSemiBold",
  },
});

import React from "react";
import { View } from "react-native";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  FlexGrid,
  Message,
  useResponsive,
  theme,
} from "@clearabee/ui-library-native";
import { useAuth } from "lib/hooks/useAuth";
import { Input } from "components/Core/Input/Input";
import { Button } from "components/Core/Button/Button";
import { Spinner } from "components/Core/Spinner/Spinner";
import { styles, ids } from "./RegisterForm.styles";
import { initialValues, validationSchema } from "./RegisterForm.validation";
import { useMutation } from "react-query";
import { useForm, Form } from "lib/hooks/useForm";

interface RegisterFormProps {
  onSuccessfulSignUp: () => void;
}

export const RegisterForm = ({
  onSuccessfulSignUp,
}: RegisterFormProps): React.ReactElement => {
  const { signUp } = useAuth();
  const [translate] = useTranslation();
  const { screens } = useResponsive();
  const { mutate, isLoading, error } = useMutation(signUp, {
    onSuccess: onSuccessfulSignUp,
  });

  const form = useForm({
    defaultValues: initialValues,
    validationSchema,
    onSubmit: (values) => mutate(values),
  });

  return (
    <Form state={form}>
      {({ Field }) => (
        <>
          <FlexGrid rowSpacing={null} style={styles.fieldsContainer}>
            <FlexGrid.Cell base="100%">
              <Field
                name="firstName"
                label={translate("common.firstName")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate("common.form.placeholder.firstName")}
                  />
                )}
              </Field>
            </FlexGrid.Cell>
            <FlexGrid.Cell base="100%">
              <Field
                name="lastName"
                label={translate("common.lastName")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate("common.form.placeholder.lastName")}
                  />
                )}
              </Field>
            </FlexGrid.Cell>
            <FlexGrid.Cell base="100%">
              <Field
                name="email"
                label={translate("common.emailAddress")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate(
                      "common.form.placeholder.emailAddress",
                    )}
                  />
                )}
              </Field>
            </FlexGrid.Cell>
            <FlexGrid.Cell base="100%">
              <Field
                name="password"
                label={translate("common.password")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate("common.form.placeholder.password")}
                    secureTextEntry
                  />
                )}
              </Field>
            </FlexGrid.Cell>
            <FlexGrid.Cell base="100%">
              <Field
                name="confirmPassword"
                label={translate("common.confirmPassword")}
                labelStyles={styles.fieldLabel}
                errorStyles={styles.fieldError}
                invertErrorColors
                errorBackground
              >
                {({ field }) => (
                  <Input.Text
                    {...field}
                    placeholder={translate(
                      "common.form.placeholder.confirmPassword",
                    )}
                    secureTextEntry
                  />
                )}
              </Field>
            </FlexGrid.Cell>
          </FlexGrid>

          <View
            style={styles.linksContainer}
            dataSet={{ media: ids.linksContainer }}
          >
            <View
              style={{ flexDirection: "row", marginTop: theme.spacing.medium }}
            >
              {isLoading && (
                <View style={{ justifyContent: "center" }}>
                  <View style={styles.spinnerContainer}>
                    <Spinner style={styles.spinner} />
                  </View>
                </View>
              )}
              <Button
                type="submit"
                color="warning"
                size={screens.medium ? "medium" : "large"}
                onClick={form.submitForm}
                disabled={isLoading}
              >
                {translate("common.createAccount")}
              </Button>
            </View>
          </View>

          {!!error && (
            <Message
              styles={styles.authError}
              color="light"
              type="error"
              background
            >
              {axios.isAxiosError(error)
                ? error.response?.data?.message
                : error instanceof Error
                ? error.message
                : JSON.stringify(error)}
            </Message>
          )}
        </>
      )}
    </Form>
  );
};

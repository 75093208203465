import { StyleSheet } from "react-native";
import { theme } from "@clearabee/ui-library-native";

export const styles = StyleSheet.create({
  websiteCredentials: {
    marginBottom: theme.spacing.small,
    width: 250,
    textAlign: "center",
    alignSelf: "center",
  },
  successEmail: {
    marginBottom: theme.spacing.medium,
    textAlign: "center",
    width: 280,
  },
});

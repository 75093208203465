import React from "react";
import { Svg } from "@clearabee/ui-library-native";

export const Trophy = (): React.ReactElement => {
  return (
    <Svg width={127} height={142}>
      <defs>
        <path id="a" d="M0 .68h45.235v35.948H0z" />
        <path id="c" d="M.503.68h45.236v35.948H.503z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <ellipse
          fillOpacity={0.1}
          fill="#000"
          cx={55.5}
          cy={128.5}
          rx={34.5}
          ry={13.5}
        />
        <path
          d="m90.151 113.287-6.44.04c-.469-.318-.957-.63-1.478-.931-10.556-6.095-27.671-6.095-38.227 0-.662.38-1.282.777-1.86 1.188l-6.057.037v9.809c0 3.994 2.639 7.988 7.917 11.035 10.556 6.095 27.671 6.095 38.227.001 5.278-3.048 7.918-7.042 7.918-11.036v-10.143Z"
          fill="#FB5607"
          fillRule="nonzero"
        />
        <path
          d="M82.234 124.323c-10.556 6.094-27.671 6.094-38.228 0-10.556-6.096-10.557-15.977 0-22.071 10.556-6.095 27.67-6.095 38.227 0 10.557 6.095 10.557 15.976 0 22.07"
          fill="#FC7839"
          fillRule="nonzero"
        />
        <path
          d="M82.234 124.323c-10.556 6.094-27.671 6.094-38.228 0-5.013-2.895-7.64-6.642-7.893-10.434-.279 4.193 2.349 8.437 7.893 11.64 10.557 6.094 27.672 6.094 38.228 0 5.544-3.202 8.17-7.446 7.891-11.638-.252 3.79-2.88 7.537-7.891 10.432"
          fill="#FDAB83"
          fillRule="nonzero"
        />
        <path
          d="m82.732 109.004-4.673.028c-.34-.23-.694-.457-1.072-.675-7.659-4.422-20.077-4.422-27.735-.001-.48.278-.93.566-1.35.863l-4.395.027v3.377c0 2.898 1.915 5.795 5.745 8.005 7.66 4.423 20.077 4.423 27.736 0 3.83-2.21 5.744-5.107 5.744-8.005v-3.62Z"
          fill="#FB5607"
          fillRule="nonzero"
        />
        <path
          d="M76.988 117.01c-7.659 4.422-20.077 4.422-27.736 0-7.66-4.421-7.66-11.592 0-16.013 7.658-4.422 20.076-4.422 27.735 0 7.66 4.422 7.66 11.592 0 16.013"
          fill="#8A2AE3"
          fillRule="nonzero"
        />
        <g transform="translate(0 38.237)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M27.28 36.627c-6.535 0-12.668-3.45-16.055-9.052L.946 10.532a6.42 6.42 0 0 1-.095-6.55A6.423 6.423 0 0 1 6.509.68h20.307v5.29H6.51c-.613 0-.921.384-1.051.613-.13.23-.299.691.018 1.216L15.753 24.84c2.467 4.078 6.945 6.585 11.729 6.495l17.674-.266.08 5.29-17.675.266c-.093.002-.186.002-.28.002"
            fill="#FFBF0A"
            fillRule="nonzero"
            mask="url(#b)"
          />
        </g>
        <g transform="translate(80.5 38.237)">
          <mask id="d" fill="#fff">
            <use xlinkHref="#c" />
          </mask>
          <path
            d="M18.458 36.627c-.093 0-.187 0-.28-.002L.503 36.36l.08-5.29 17.675.266c4.757.108 9.261-2.417 11.73-6.497l10.274-17.04c.317-.525.147-.987.019-1.216a1.169 1.169 0 0 0-1.052-.613H18.923V.68H39.23a6.424 6.424 0 0 1 5.658 3.303 6.423 6.423 0 0 1-.095 6.55l-10.278 17.04a18.83 18.83 0 0 1-16.057 9.054"
            fill="#FFBF0A"
            fillRule="nonzero"
            mask="url(#d)"
          />
        </g>
        <path
          d="M52.615 99.763c5.801 3.35 15.207 3.35 21.008 0 5.801-3.348 5.801-8.779 0-12.128-5.8-3.35-15.206-3.35-21.007 0-5.8 3.349-5.801 8.78 0 12.128"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M45.774 102.276c3.13-2.324 2.525-4.595 2.525-4.595l2.367 2.857c7.2-3.473 17.713-3.473 24.91.002l2.369-2.86s-.603 2.272 2.525 4.596c3.129 2.322 2.108 6.883 2.108 6.883l-.006-.007c.003 2.877-1.897 5.754-5.697 7.949-7.598 4.385-19.915 4.385-27.511 0-3.798-2.193-5.698-5.068-5.698-7.942 0-.002-1.02-4.562 2.108-6.883"
          fill="#FFBF0A"
          fillRule="nonzero"
        />
        <path
          d="M73.987 102.958s-5.62-3.326-5.62-4.027c0-.102.116-.289.305-.522 2.47.424 4.828 1.127 6.904 2.13l2.369-2.858s-.603 2.271 2.526 4.594c3.127 2.324 2.107 6.884 2.107 6.884l-.006-.006c.002 1.57-.584 3.134-1.715 4.593.051-.355.65-5.056-2.883-6.594-3.673-1.6-3.987-4.194-3.987-4.194"
          fill="#FFBF0A"
          fillRule="nonzero"
        />
        <path
          d="M52.615 103.177c5.801 3.348 15.207 3.348 21.008 0 5.801-3.35 5.801-8.78 0-12.13-5.8-3.348-15.206-3.348-21.007 0-5.8 3.35-5.801 8.78 0 12.13M35.645 55.095c15.174 8.76 39.774 8.76 54.948 0 15.175-8.76 15.175-22.965.002-31.725-15.174-8.76-39.776-8.76-54.95 0-15.174 8.761-15.174 22.965 0 31.725"
          fill="#FFBF0A"
          fillRule="nonzero"
        />
        <path
          d="M101.974 39.233c-.39 21.656-11.555 24.278-17.528 32.384-5.574 7.565-4.94 18.505-4.83 19.932.002.053.014.106.013.16 0 2.439-1.612 4.878-4.836 6.739-6.447 3.723-16.901 3.723-23.348 0-3.022-1.744-4.609-3.998-4.798-6.283l-.038.001v-.46c0-.05.012-.103.014-.154.108-1.416.746-12.365-4.831-19.935-5.973-8.106-17.137-10.728-17.527-32.384h77.71Z"
          fill="#FFBF0A"
          fillRule="nonzero"
        />
        <path
          d="M40.06 52.546c12.735 7.353 33.382 7.352 46.118 0 12.735-7.353 12.735-19.274 0-26.627-12.734-7.352-33.382-7.352-46.117 0-12.735 7.354-12.735 19.275-.001 26.627"
          fill="#CC9908"
          fillRule="nonzero"
        />
        <path
          d="M86.179 27.59c-12.735-7.352-33.383-7.352-46.118 0-6 3.465-9.17 7.943-9.518 12.48-.389-5.094 2.781-10.261 9.518-14.15 12.735-7.353 33.383-7.353 46.118 0 6.735 3.888 9.905 9.054 9.516 14.147-.346-4.536-3.518-9.013-9.516-12.476"
          fill="#FFCF48"
          fillRule="nonzero"
          opacity={0.6}
        />
        <g fillRule="nonzero">
          <path
            d="M63.329 57.974c18.313 0 33.788-6.503 33.788-24.816S81.57 0 63.258 0C44.945 0 29.4 15.545 29.4 33.858c0 18.313 15.616 24.116 33.929 24.116Z"
            fill="#00D42A"
          />
          <path
            d="M96.5 27.5c-.5-4.5-1.215-8.917-8.5-17C82.026 3.872 72.51 0 64.258 0 45.945 0 97.147 33.32 96.5 27.5Z"
            fill="#3D5"
          />
          <path
            d="M80.036 20.312a1.944 1.944 0 0 0-2.636-.002L53.965 42.34l-5.898-5.077c-.762-.652-1.938-.603-2.632.112a1.685 1.685 0 0 0 .118 2.48l7.212 6.207c.113.097.246.15.373.215.07.038.13.093.204.12.218.08.45.124.68.124.477 0 .954-.171 1.317-.513l24.694-23.214c.728-.685.73-1.795.003-2.482"
            fill="#FFF"
          />
        </g>
        <path
          d="M35.645 55.095c15.174 8.76 39.774 8.76 54.948 0 7.154-4.13 10.931-9.47 11.34-14.878.46 6.065-3.318 12.218-11.34 16.848-15.174 8.761-39.774 8.761-54.948 0-8.021-4.63-11.798-10.781-11.34-16.845.41 5.407 4.187 10.746 11.34 14.875"
          fill="#FFE7A3"
          fillRule="nonzero"
          opacity={0.6}
        />
        <path
          d="M86.034 50.633c6.057-3.58 10.656-11.008 11.002-15.697.39 5.258-3.443 13.9-10.236 17.914-12.85 7.594-35.1 7.244-47.95-.35-6.792-4.014-9.838-11.894-9.45-17.15.347 4.687 4.045 11.704 10.102 15.283 12.85 7.594 33.682 7.594 46.532 0Z"
          fill="#0A2"
          fillRule="nonzero"
          opacity={0.6}
        />
        <path
          d="M46.622 91.552c.014-.17.034-.482.05-.907.25 2.23 1.822 4.418 4.773 6.122 6.447 3.723 16.9 3.723 23.348 0 2.887-1.667 4.477-3.797 4.78-5.975.015.346.031.607.043.757.002.053.014.106.013.16 0 2.439-1.612 4.878-4.836 6.74-6.447 3.722-16.9 3.722-23.348 0-3.022-1.746-4.608-3.998-4.798-6.284h-.038v-.458c0-.052.012-.103.013-.155"
          fill="#CC9908"
          fillRule="nonzero"
          opacity={0.6}
        />
      </g>
    </Svg>
  );
};
